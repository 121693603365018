import React from "react";
import * as styles from "./userimage.module.scss";

export default ({ email, style, onClick }) => {
  let letter = email ? email.charAt(0).toUpperCase() : "P";
  return (
    <div className={styles.container} style={style} onClick={onClick}>
      {letter}
    </div>
  );
};
