import React from 'react';
import DownSvg from '../../../assets/svg/arrow_down.svg';
import Popout from '../../Common/Popout/Popout';
import * as styles from './popouttab.module.scss';

const PopoutTab = ({title, parent, children}) => {
    return(
      <Popout parent={
        <div className={styles.title}>
            <span>{title}</span>
            <DownSvg />
        </div>
      }>
        {children}
      </Popout>
    )
}

export default PopoutTab;