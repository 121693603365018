import React from 'react'
import Services from '../Services/Services'
import HamburgerSvg from '../../../assets/svg/menu/hamburger.svg'
import CloseIcon from '../../../assets/svg/3d-maker/close.svg';
import * as styles from './mobile.module.scss'
import { Link } from 'gatsby'
import Firebase from '../../../firebase/index'
import { useSelector } from 'react-redux';

export default () => {
    const [open, setOpen] = React.useState(false)
    let user = useSelector(state => state.UserSlice.user)
    return(
        <div className={styles.container}>
            <div className={styles.hamburger} onClick={() => setOpen(true)}>
                <HamburgerSvg />
            </div>
            <div className={`${styles.navigation} ${open ? styles.open : null}`}>
                    <CloseIcon className={styles.close} onClick={() => setOpen(false)}/>
                    <span className={styles.title}>Mockups</span>
                    <Services />
                    <div className={styles.actions}>
                        <Link to={'/plans'}>Pricing</Link>
                        {user ? 
                        <>
                        <Link to={'/account'}>My Account</Link>
                        <span onClick={Firebase.signOut}>Sign Out</span>
                        </>
                        :
                        <>
                        <Link to={'/login'}>Login</Link>
                        <Link to={'/register'}>Register</Link>
                        </>}
                    </div>
            </div>
        </div>
    )
}