import { Link } from 'gatsby'
import React from 'react'
import AppStoreVideoSvg from '../../../assets/svg/menu/appstore-video.svg'
import AppStoreSvg from '../../../assets/svg/menu/appstore.svg'
import DesktopSvg from '../../../assets/svg/menu/desktop.svg'
import LaptopSvg from '../../../assets/svg/menu/laptop.svg'
import PhoneSvg from '../../../assets/svg/menu/phone.svg'
import TabletSvg from '../../../assets/svg/menu/tablet.svg'
import ThreeAnimations from '../../../assets/svg/menu/ThreeAnimations.svg'
import ThreeMockups from '../../../assets/svg/menu/ThreeMockups.svg'
import InstagramSvg from '../../../assets/svg/social/instagram.svg'
import TwitterSvg from '../../../assets/svg/social/twitter.svg'
import LinkedInSvg from '../../../assets/svg/social/linkedin.svg'
import FacebookSvg from '../../../assets/svg/social/facebook.svg'
import * as styles from './services.module.scss'




function ServiceItem({service}){
    let {icon: Icon, iconColor, iconBackground, name, to} = service
    return(
        <Link to={to} className={styles.serviceItem}>
            <div className={styles.serviceIcon} style={{backgroundColor: iconBackground}}>
                <Icon fill={iconColor}/>
            </div>
            <div className={styles.title}>
                {name}
            </div>
        </Link>
    )
}

function Category({title, services}){
    const Items = () => services.map((service, index) => {
        return(
            <ServiceItem service={service} key={index} />
        )
    })
    return(
        <div className={styles.category}>
            <span className={styles.categoryTitle}>{title}</span>
            <div>
                <Items />
            </div>
        </div>
    )
}

export default () => {
    let categoryOneServices = [
        {
            icon: AppStoreSvg,
            name: 'App Store Mockups',
            iconColor: '#6890F1',
            iconBackground: '#F0F4FE',
            to: '/mockups/screenshots/appstore'
        },
        {
            icon: AppStoreSvg,
            name: 'Google Play Mockups',
            iconColor: '#8660FE',
            iconBackground: '#F3EFFF',
            to: '/mockups/screenshots/playstore'
        },
        {
            icon: AppStoreVideoSvg,
            name: 'App Store Video',
            iconColor: '#6291F1',
            iconBackground: '#F0F4FE',
            to: '/video'
        },
    ]

    let categoryTwoServices = [
        {
            icon: PhoneSvg,
            name: 'iPhone Mockups',
            iconColor: '#28CF69',
            iconBackground: '#EAFAF0',
            to: '/mockups/apple/iphone'
        },
        {
            icon: LaptopSvg,
            name: 'MacBook Mockups',
            iconColor: '#E477FB',
            iconBackground: '#FFF4F8',
            to: '/mockups/apple/mac/macbook'
        },
        {
            icon: TabletSvg,
            name: 'iPad Mockups',
            iconColor: '#906EFC',
            iconBackground: '#FCF2FF',
            to: '/mockups/apple/ipad'
        },
        {
            icon: DesktopSvg,
            name: 'iMac Mockups',
            iconColor: '#36BEDF',
            iconBackground: '#EBF9FC',
            to: '/mockups/apple/mac/imac-2021'
        }
    ]

    let categoryThreeServices = [
        {
            icon: ThreeAnimations,
            name: '3D Animations',
            iconColor: '#FF8D43',
            iconBackground: '#FFF4ED',
            to: '/mockups/3d/animations'
        },
        {
            icon: ThreeMockups,
            name: '3D Mockups',
            iconColor: '#EF3D7E',
            iconBackground: '#FEF4F7',
            to: '/mockups/3d/images'
        },
        {
            icon: AppStoreVideoSvg,
            name: 'App Promo Video (New!)',
            iconColor: '#000000',
            iconBackground: '#efefef',
            to: '/app-promo-video'
        },

    ]

    let categoryFourServices = [
        {
            icon: InstagramSvg,
            name: 'Instagram',
            iconColor: '#C13584',
            iconBackground: '#ffedfc',
            to: '/mockups/social-media/instagram'
        },
        {
            icon: FacebookSvg,
            name: 'Facebook',
            iconColor: '#4267B2',
            iconBackground: '#f4f6fe',
            to: '/mockups/social-media/facebook'
        },
        {
            icon: TwitterSvg,
            name: 'Twitter',
            iconColor: '#1DA1F2',
            iconBackground: '#edf9ff',
            to: '/mockups/social-media/twitter'
        },
        {
            icon: LinkedInSvg,
            name: 'LinkedIn',
            iconColor: '#2867B2',
            iconBackground: '#f5f5f5',
            to: '/mockups/social-media/linkedin'
        }
    ]
    return(
        <div className={styles.container}>
            <Category title={'AppStore & Google Play'} services={categoryOneServices}/>
            <Category title={'Apple Device Mockups'} services={categoryTwoServices}/>
            <Category title={'3D'} services={categoryThreeServices}/>
            <Category title={'Social Media'} services={categoryFourServices}/>
        </div>
    )
}