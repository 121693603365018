import { Link } from 'gatsby'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginModal } from '../../../slices/user-slice'
import Account from './Account/Account'
import * as styles from './rightside.module.scss'

export default () => {
    let dispatch = useDispatch()
    let user = useSelector(state => state.UserSlice.user)
    return(
        <div className={styles.container}> 
            {user ? 
            <Account /> : 
            <>
            <span onClick={() => dispatch(setLoginModal(true))}>Login</span>
            <Link to={'/register'}>
            <button className={styles.signUpButton}>
                Register
            </button>
            </Link>
            </>}
        </div>
    )
}