import React from 'react'
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import * as styles from './popout.module.scss'

const Popout = ({parent, children, trigger}) => {
    return(
        <Tippy
        className={styles.container}
        maxWidth={'none'}
        placement={'bottom-start'}
        theme={"light"}
        content={children}
        trigger={trigger || "mouseenter"}
        interactive={true}
      >
          {parent}
      </Tippy>
    )
}

export default Popout;