import { Link } from "gatsby";
import React from "react";
import API from "../../../../firebase/api";
import Firebase from "../../../../firebase/index";
import MyTemplatesSvg from "../../../../assets/svg/my-templates.svg";
import SignOutSvg from "../../../../assets/svg/logout.svg";
import DownloadSvg from "../../../../assets/svg/download.svg";
import MyAccountSvg from "../../../../assets/svg/account.svg";
import { useSelector } from "react-redux";
import UserImage from "../../../Common/UserImage/UserImage";
import * as styles from "./account.module.scss";

export default () => {
  const [open, setOpen] = React.useState(false);
  const [userCredits, setUserCredits] = React.useState(0);

  let dropdownRef = React.useRef();

  let userInfo = useSelector((state) => state.UserSlice.userInfo);


  React.useEffect(() => {
    async function getUserCredits() {
      let response = await API.canUserDownload();
      if (response){

        if (response.unlimited || response.subscription) {
          setUserCredits("Unlimited");
        } else {
          let totalCredits =
            response.credits !== 0
              ? response.credits
              : response["has-free-download"]
              ? "Available"
              : 0;
          setUserCredits(totalCredits);
        }

      } else {
        setUserCredits(0);
      }

    }
    getUserCredits();
  }, []);


  React.useEffect(() => {
    function setListener(e) {
      if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
        // Clicked in box
      } else {
        setOpen(false);
      }
    }
    if (open && dropdownRef.current) {
      setTimeout(() => {
        document.addEventListener("click", setListener);
      }, 100)

    }
    return () => {
      document.removeEventListener("click", setListener);
    };
  }, [open, dropdownRef.current]);
  
  if(!userInfo){
    return null
  }
  
  return (
    <div className={styles.container}>
      <div
        className={styles.account}
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <UserImage
          email={userInfo.email}
        />
      </div>
      {open ? (
        <div className={styles.dropdown} ref={dropdownRef}>
          {/* <div className={styles.cover} onClick={() => setOpen(false)}/> */}
          <div className={styles.userInfoDiv}>
            <UserImage
              email={userInfo.email}
              style={{ width: 40, height: 40, marginRight: 10 }}
            />
            <div>
              <span style={{ marginBottom: 10 }}>{userInfo.email}</span>
              <span>Downloads: {userCredits}</span>
            </div>
          </div>
          <ul>
            <Link to="/account">
              <li>
                <MyAccountSvg /> My Account
              </li>
            </Link>
            {userCredits !== "Unlimited" ? (
              <Link to="/plans">
                <li>
                  <DownloadSvg /> Buy Downloads
                </li>
              </Link>
            ) : null}
            <Link to="/templates">
              <li>
                <MyTemplatesSvg /> My Templates
              </li>
            </Link>
            <li
              onClick={() => {
                Firebase.signOut();
                setOpen(false);
              }}
            >
              <SignOutSvg /> Sign Out
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};
