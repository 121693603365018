import { Link } from "gatsby";
import React from "react";
import * as styles from "./leftside.module.scss";
import PopoutTab from "../PopoutTab/PopoutTab";
import Services from "../Services/Services";
import UseCasesHeader from "../UseCases/UseCases";
import Features from "../Features/Features";

export default (_) => {
  return (
    <div className={styles.container}>
      <Features />
      <UseCasesHeader />
      <PopoutTab title={"Templates"}>
        <Services />
      </PopoutTab>
      <Link
        to={"/plans"}
        style={{ marginLeft: 20 }}
        activeClassName={styles.activeLink}
      >
        Pricing
      </Link>
    </div>
  );
};
