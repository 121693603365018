import React from 'react'
import Logo from '../Common/Logo/Logo'
import LeftSide from './LeftSide/LeftSide'
import * as styles from './header.module.scss'
import RightSide from './RightSide/RightSide'
import Mobile from './Mobile/Mobile'

export default () => {
    return(
        <div className={styles.container}>
            <div className={styles.logoDiv}>
                <Logo />
            </div>
            <div className={styles.desktop}>
                <LeftSide />
                <RightSide />
            </div>
            <Mobile />
        </div>
    )
}