import React from "react"
import Header from './Header/Header'
import Footer from './Footer/Footer'

export default function Layout({ children }) {
    return(
        <>
            <Header />
            <div style={{minHeight: 'calc(100vh - 340px)', display: 'flex', flexDirection: 'column'}}>
                {children}
            </div>
            <Footer />
        </>
    )
}