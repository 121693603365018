import { Link } from "gatsby";
import React from "react";

import InstagramIcon from "../../assets/svg/instagram.svg";
import TwitterIcon from "../../assets/svg/twitter_share.svg";

import * as styles from "./footer.module.scss";

export default () => {
    const Column = ({heading, options}) => {
        let List = () => options.map((option, index) => {
            return (
                <li key={index}>
                    <Link to={option.to}>
                        {option.name}
                    </Link>
                </li>
            )
        })
        return(
            <div className={styles.column}>
                <h5>
                    {heading}
                </h5>
                <ul>
                    <List />
                </ul>
            </div>
        )
    }
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.innerFlex}>
          <div className={styles.left}>
            <h4>Pixel perfect mockups for your next app</h4>
            <div className={styles.socials}>
                <a href={'https://instagram.com/previewedapp/'} rel="noopener" target={'_blank'}>
                    <InstagramIcon />
                </a>
                <a href={'https://twitter.com/PreviewedApp'} rel="noopener" target={'_blank'}>
                    <TwitterIcon />
                </a>
            </div>
          </div>
          <div className={styles.right}>
              <Column heading={'Features'} options={[
                {
                    name: 'Mockup Videos with Devices',
                    to: '/video-mockup'
                },
                {
                    name: 'Device Animation',
                    to: '/device-animation'
                },
              ]} />
            <Column heading={'Use Cases'} options={[
                {
                    name: 'App Promo Video',
                    to: '/app-promo-video'
                },
                {
                    name: 'App Store Screenshot Generator',
                    to: '/app-store-screenshot-generator'
                },
              ]} />
              <Column heading={'Mockups'} options={[
  
                    {
                    name: 'iPhone Mockups',
                    to: '/mockups/apple/iphone'
                    },
                    {
                    name: 'MacBook Mockups',
                    to: '/mockups/apple/mac'
                    },
                    {
                    name: 'iPad Mockups',
                    to: '/mockups/apple/iPad'
                    },
                    {
                        name: 'Apple Devices Mockups',
                        to: '/mockups/apple'
                    },
                    {
                    name: '3D Mockups',
                    to: '/mockups/3d'
                    },
                    {
                    name: 'Social Media Mockups',
                    to: '/mockups/social-media'
                    },
                    {
                    name: 'Promo Banners',
                    to: '/mockups/app-promo-banners'
                    },
                    {
                    name: 'Web Browser Mockups',
                    to: '/mockups/web-browser'
                    },
              ]} />
                            <Column heading={'Legal'} options={[
                  {
                      name: 'Terms and Conditions',
                      to: '/terms_and_conditions/'
                  },
                  {
                    name: 'Privacy Policy',
                    to: '/privacy_policy'
                },
              ]} />
                                          <Column heading={'About'} options={[
                                              {
                                                  name: 'Blog',
                                                  to: 'https://blog.previewed.app/'
                                              },
                                              {
                                                name: 'Affiliates',
                                                to: '/affiliate'
                                            },
                  {
                      name: 'Contact Us',
                      to: '/contact'
                  },
                  {
                    name: 'Changelog',
                    to: '/changelog'
                    }
              ]} />
          </div>
        </div>
        <span className={styles.copyright}>© Copyright 2021. All Rights Reserved.</span>
      </div>
    </div>
  );
};
