import { Link } from 'gatsby';
import React from 'react'
import PopoutTab from '../PopoutTab/PopoutTab';
import AppStoreSvg from '../../../assets/svg/menu/appstore.svg'
import PhoneSvg from '../../../assets/svg/menu/phone.svg'
import PlaySvg from '../../../assets/svg/menu/appstore-video.svg'
import * as styles from './features.module.scss'

const FeaturesHeader = () => {
    const cases = [
        {
            title: "Add Video to Device",
            subtitle: "Frame videos inside Phones & Tables",
            to: "/video-mockup",
            icon: PhoneSvg,
            iconColor: '#EE6123',
            iconBackground: '#FEF1EC',
        },
        {
            title: "Device Animations",
            subtitle: "Animate Phones, Tables & Laptops",
            to: "/device-animation",
            icon: AppStoreSvg,
            iconColor: '#613DC1',
            iconBackground: '#F2F0FA',
        }
    ]
    const Features = () => cases.map((item, index) => {
        const {icon: Icon,iconColor, iconBackground, title, subtitle, to} = item
        return(
            <li key={index}>
            <Link to={to}>
            <div>
                <div className={styles.left} style={{background: iconBackground}}>
                    <Icon fill={iconColor}/>
                </div>
                <div className={styles.right}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.subtitle}>{subtitle}</span>
                </div>
            </div>
            </Link>
        </li>
        )
    })
    return(
        <PopoutTab title={"Features"}>
            <div className={styles.container}>
                <ul>
                    <Features />
                </ul>
            </div>
        </PopoutTab>
    )
}

export default FeaturesHeader;