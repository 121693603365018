import { Link } from 'gatsby';
import React from 'react'
import PopoutTab from '../PopoutTab/PopoutTab';
import AppStoreSvg from '../../../assets/svg/menu/appstore.svg'
import PhoneSvg from '../../../assets/svg/menu/phone.svg'
import PlaySvg from '../../../assets/svg/menu/appstore-video.svg'
import * as styles from './usecases.module.scss'

const UseCasesHeader = () => {
    const cases = [
        {
            title: "iPhone Mockups",
            subtitle: "3D iPhone Shots & Animations",
            to: "/iphone-mockup-generator",
            icon: PhoneSvg,
            iconColor: '#28CF69',
            iconBackground: '#EAFAF0',
        },
        {
            title: "App Store Screenshots",
            subtitle: "For app store listings",
            to: "/app-store-screenshot-generator",
            icon: AppStoreSvg,
            iconColor: '#6890F1',
            iconBackground: '#F0F4FE',
        },
        {
            title: "App Promo Video (beta)",
            subtitle: "For mobile & web apps",
            to: "/app-promo-video",
            icon: PlaySvg,
            iconColor: '#E477FB',
            iconBackground: '#FFF4F8',
        }
    ]
    const Cases = () => cases.map((item, index) => {
        const {icon: Icon,iconColor, iconBackground, title, subtitle, to} = item
        return(
            <li key={index}>
            <Link to={to}>
            <div>
                <div className={styles.left} style={{background: iconBackground}}>
                    <Icon fill={iconColor}/>
                </div>
                <div className={styles.right}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.subtitle}>{subtitle}</span>
                </div>
            </div>
            </Link>
        </li>
        )
    })
    return(
        <PopoutTab title={"Use Cases"}>
            <div className={styles.container}>
                <ul>
                    <Cases />
                </ul>
            </div>
        </PopoutTab>
    )
}

export default UseCasesHeader;